
.logoText{
    font-weight:800;
    font-size:1.5em;
    vertical-align:middle;
    color:#ffffff;
}

.togglerbtn {
    position: relative;
    border: none;
    outline: none;
    background-color: transparent;
    margin-left: auto;
    transition: background-color .3s;
  }
  
   
  .togglerbtn:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 0;
    border-radius: 100%;
    
    background-color: rgba(236, 240, 241, .3);
    
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

.togglerbtn:active:before {
    width: 120%;
    padding-top: 120%;
    transition: width .1s ease-out, padding-top .1s ease-out;
}

.navbarNav li {
    padding: 5px 5px;
}

.header {
    /* border-bottom: 2px solid #007759; */
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    background-color: #5b89ff; 
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 1rem;
}




/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .navbarNav {
        margin-left: 0;
    }
    .logoText {
        font-size:1em;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .navbarNav {
        margin-left: 0;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .navbarNav {
        margin-left: 0;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .navbarNav{
        margin-left: 40rem;
    }
    .togglerbtn {
        display: none;
    }
}

